// ============================================================================
// SSOService
// ----------
// SSO module related services
// ============================================================================

// -------
// Imports
// -------
import { Config } from '@/services/ConfigService'
import API from '@/apis/SSOApi'

import AppService from '@/services/AppService'

// -------
// Exports
// -------
export default {
	getECMSSO: function (accountingFirmId, appName) {
		return API.getECMSSO(accountingFirmId, appName).then(res => res.data.data.link)
	},
	connectTo: function (providerId) {
		const token = localStorage.getItem('token')
		window.open(`${Config.VUE_APP_ROOT_API}/api/oauth/connect-for-sso/${providerId}?token=${encodeURIComponent(token)}`)
		AppService.goTo({ path: '/home' })
	}
}
