<template>
	<v-layout fill-height></v-layout>
</template>

<script>
import SSOModuleGuard from '@/mixins/ModulesGuards/SSO/SSOModuleGuard'

export default {
	name: 'SSOGoTo',
	mixins: [SSOModuleGuard],
	created: function () {
		const providerId = this.$route.params.providerId
		this.service.connectTo(providerId)
	}
}
</script>
