import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/oauth/')

const getECMSSO = (accountingFirmId, appName) => {
	const url = `get-sso-link/${accountingFirmId}/ged/${appName}`
	return axios.get(url)
}

export default {
	getECMSSO: getECMSSO
}
